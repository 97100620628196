import { Component } from '@angular/core';

@Component({
    selector: 'app-cross-circle-inverse-icon',
    imports: [],
    template: `<svg
        fill="currentColor"
        height="100%"
        viewBox="0 0 20 20"
        width="100%"
        xmlns="http://www.w3.org/2000/svg">
        <rect height="20" rx="10" transform="matrix(1 0 0 -1 0 20)" width="20" />
        <path d="M14 14L6 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 14L14 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg> `,
    styles: [],
})
export class CrossCircleInverseIconComponent {}
